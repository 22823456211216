import axios from "axios";

const api = axios.create({
 baseURL: "https://nossabossa-api.salesbreath.com.br/api/v1",

 // baseURL: "http://localhost:3009/api/v1/",
  headers: { "Content-Type": "application/json" }
});

export default api;
